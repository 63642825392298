import Vue from 'vue';
import VueRouter from 'vue-router';
import HomePage from '../components/HomePage.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage,
  },
  {
    path: '/g/:id',
    name: 'game',
    component: () => import(/* webpackChunkName: "game" */ '../components/GamePage.vue'),
  },
  {
    path: '/referral-program',
    name: 'referral-program',
    component: () => import(/* webpackChunkName: "referral-program" */ '../components/ReferralPage.vue'),
  },
  {
    path: '/shop',
    name: 'shop',
    component: () => import(/* webpackChunkName: "shop" */ '../components/ShopPage.vue'),
  },
  {
    path: '/packs',
    name: 'packs',
    component: () => import(/* webpackChunkName: "packs" */ '../components/PacksPage.vue'),
  },
  {
    path: '/packs/:type',
    name: 'packs-type',
    component: () => import(/* webpackChunkName: "packs-type" */ '../components/PacksPage.vue'),
  },
  {
    path: '/game-list',
    name: 'game-list',
    component: () => import(/* webpackChunkName: "game-list" */ '../components/GameListPage.vue'),
  },
  {
    path: '/games',
    name: 'games',
    component: () => import(/* webpackChunkName: "games" */ '../components/GamesPage.vue'),
  },
  {
    path: '/games/:category',
    name: 'packs-category',
    component: () => import(/* webpackChunkName: "games-category" */ '../components/GamesPage.vue'),
  },
  {
    path: '/account',
    name: 'account',
    component: () => import(/* webpackChunkName: "account" */ '../components/AccountPage.vue'),
  },
  {
    path: '/account/:page',
    name: 'account-page',
    component: () => import(/* webpackChunkName: "account-page" */ '../components/AccountPage.vue'),
  },
  {
    path: '/page/:page',
    name: 'template-page',
    component: () => import(/* webpackChunkName: "template-page" */ '../components/TemplatePage.vue'),
  },
  {
    path: '*',
    name: 'not-found-page',
    component: () => import(/* webpackChunkName: "not-found-page" */ '../components/NotFoundPage.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
