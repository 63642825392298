<template>
  <div class="overlay" :active="opened" @click="close">
    <div class="modal" @click.stop v-if="gameForm">
      <div class="modal-header">
        <div class="modal-title">🕹️ {{ t('Create a game', lang) }}</div>
        <a @keypress.space="close" @click="close">
          <span class="material-symbols-outlined">close</span>
        </a>
      </div>
      <div class="modal-content">
        <div class="form game-form">
          <div class="form-step" v-if="step === 1">
            <div class="field">
              <div class="field-label">{{ t('Game name', lang) }}</div>
              <div class="field-group">
                <input type="text" maxlength="20" class="input" :placeholder="t('Game name', lang)" :disabled="loading" v-model="gameForm.name">
                <!-- <DropdownButton :items="languageItems" width="100%" :selected="gameForm.language" @change="gameForm.language = $event.value">
                  {{ gameForm.language }}
                </DropdownButton> -->
              </div>
            </div>
            <div class="field">
              <div class="field-label">{{ t('Select a game mode', lang) }}</div>
              <div class="game-modes" :selected="!!gameForm.mode">
                <button :class="`game-mode ${gameMode.id}`" v-for="(gameMode, index) in gameModes" :key="gameMode.id + index" :selected="gameMode === gameForm.mode" @click="gameForm.mode = gameMode">
                  <div class="game-mode-title">{{ gameMode.icon }} {{ gameMode.title }}</div>
                  <div class="game-mode-description">{{ gameMode.description }}</div>
                </button>
              </div>
            </div>
            <div class="actions">
              <button
                class="btn"
                :disabled="loading || !gameForm.name.length || !gameForm.mode"
                @click="step = 2"
              >{{ t('Next', lang) }}</button>
            </div>
          </div>
          <div class="form-step" v-else>
            <div class="field">
              <div class="field-label">{{ t('Number of rounds', lang) }}</div>
              <div class="input-slider">
                <input type="range" min="1" max="50" :disabled="loading" v-model="gameForm.turns">
                {{ gameForm.turns }}
              </div>
            </div>
            <!-- <div class="field">
              <div class="field-label">{{ t('Response time', lang) }}</div>
              <div class="input-slider">
                <input type="range" min="10" max="300" step="5" :disabled="loading" v-model="gameForm.turn_duration">
                {{ gameForm.turn_duration }} {{ t('seconds', lang) }}
              </div>
            </div> -->
            <div class="field">
              <div class="field-label">{{ t('Max players', lang) }}</div>
              <div class="input-slider">
                <input type="range" min="3" max="16" step="1" :disabled="loading" v-model="gameForm.max_players">
                {{ gameForm.max_players }}
              </div>
            </div>
            <div class="field" v-if="!isFreeForm && !useManualScore">
              <div class="field-label">{{ t('Cards per player', lang) }}</div>
              <div class="input-slider">
                <input type="range" min="1" max="10" step="1" :disabled="loading" v-model="gameForm.cards_per_player">
                {{ gameForm.cards_per_player }}
              </div>
            </div>
            <div class="field inline">
              <div class="field-label">{{ t('Prompt Selection', lang) }} <i>{{ t('If checked, the player will be able to choose between 3 prompt cards.', lang) }}</i></div>
              <ToggleButton v-model="gameForm.prompt_selection" :disabled="loading" />
            </div>
            <div class="field inline" v-if="!useManualScore">
              <div class="field-label">{{ t('Active Judge', lang) }} <i>{{ t('If checked, the judge will also be able to answer prompts.', lang) }}</i></div>
              <ToggleButton v-model="gameForm.allow_judge_action" :disabled="loading" />
            </div>
            <div class="field inline" v-if="!useManualScore">
              <div class="field-label">{{ t('Players Vote', lang) }} <i>{{ t('If checked, all players can vote on answers.', lang) }}</i></div>
              <ToggleButton v-model="gameForm.allow_players_votes" :disabled="loading" />
            </div>
            <div class="field inline" v-if="!useManualScore">
              <div class="field-label">{{ t('Audience Vote', lang) }} <i>{{ t('If checked, the audience can vote on answers.', lang) }}</i></div>
              <ToggleButton v-model="gameForm.allow_audience_votes" :disabled="loading" />
            </div>
            <div class="field inline">
              <div class="field-label">{{ t('Public Game', lang) }} <i>{{ t('If checked, this game will be listed publicly.', lang) }}</i></div>
              <ToggleButton v-model="gameForm.is_public" :disabled="loading" />
            </div>
            <div class="field inline" v-if="!useManualScore">
              <div class="field-label">{{ t('Anonymous Answers', lang) }} <i>{{ t('If checked, the player\'s name is not displayed in their answers.', lang) }}</i></div>
              <ToggleButton v-model="gameForm.anonymous_answers" :disabled="loading" />
            </div>
            <div class="actions">
              <button class="btn ghost" :disabled="loading" @click="step = 1">
                {{ t('Back', lang) }}
              </button>
              <button
                class="btn"
                :disabled="loading"
                @click="createGame"
              >{{ t('Start', lang) }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { showError } from '../utils/error-handling';
import { sendEvent } from '../services/firebase';
import ToggleButton from './ToggleButton.vue';

export default {
  name: 'GameModal',
  components: {
    ToggleButton,
  },
  computed: {
    ...mapState(['client', 'lang', 'gameModes', 'user']),
    isFreeForm() {
      return ['free-form', '3words', 'downtown', 'blanks'].includes(this.gameForm.mode.id);
    },
    useManualScore() {
      return ['drink'].includes(this.gameForm.mode.id);
    },
  },
  data() {
    return {
      opened: false,
      loading: false,
      gameForm: null,
      step: 1,
    };
  },
  methods: {
    ...mapActions(['showMessage']),
    open() {
      this.gameForm = {
        name: `${this.user.name}'s game`,
        mode: this.gameModes[0],
        language: 'EN',
        turns: 15,
        turn_duration: 60,
        max_players: 14,
        cards_per_player: 7,
        allow_audience_votes: false,
        allow_judge_action: false,
        allow_players_votes: false,
        prompt_selection: false,
        is_public: false,
        is_manual: true,
        anonymous_answers: true,
      };
      this.step = 1;
      this.opened = true;
    },
    close() {
      if (this.loading) {
        return;
      }
      this.gameForm = null;
      this.opened = false;
    },
    async createGame() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      try {
        const settings = { ...this.gameForm };
        if (settings.mode.decks) {
          settings.decks = settings.mode.decks;
        }
        settings.mode = settings.mode.id;
        const game = await this.client.post('/games', settings);
        settings.id = game.id;
        this.loading = false;
        this.close();
        sendEvent('create-game', settings);
        this.$router.push(`/g/${game.id}`);
      } catch (error) {
        showError(error);
      }
      this.loading = false;
    },
  },
  mounted() {
    window.addEventListener('open-game', this.open);
  },
  beforeDestroy() {
    window.removeEventListener('open-game', this.open);
  },
};
</script>

<style scoped>
.modal {
  width: 800px;
  padding: 12px 32px 24px;
  align-self: flex-start;
}

.game-modes {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.game-modes .game-mode {
  width: calc(50% - 8px);
  text-align: left;
  background: rgba(90,76,212,1);
  background: linear-gradient(142deg, rgba(90,76,212,1) 0%, rgba(60,51,115,1) 50%);
  padding: 12px 16px;
  border-radius: 8px;
  opacity: 0.5;
}
.game-modes .game-mode.questions {
  background: rgba(253,186,53,1);
  background: linear-gradient(142deg, rgba(253,186,53,1) 0%, rgb(222, 148, 1) 50%);
}
.game-modes .game-mode.texts {
  background: rgba(76, 209, 253, 1);
  background: linear-gradient(142deg, rgba(76, 209, 253, 1) 0%, rgb(0, 160, 214) 50%);
}
.game-modes .game-mode.lyrics {
  background: rgb(236 51 126);
  background: linear-gradient(142deg, rgb(236 51 126) 0%, rgb(204, 8, 86) 50%);
}

.game-modes .game-mode[selected] {
  opacity: 1;
}

.game-modes .game-mode .game-mode-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 8px;
}

.form .actions {
  justify-content: center;
}
</style>
