<template>
  <button class="toggle-btn" type="button" @click="$emit('input', !value)" :active="value" :disabled="disabled">
    <span class="toggle-thumb"></span>
  </button>
</template>

<script>
export default {
  name: 'ToggleButton',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.toggle-btn {
  width: 56px;
  height: 24px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.08);
  border: none;
  padding: 0;
  margin: 4px 0;
  flex-shrink: 0;
  transition: background 0.2s ease-in;
}

.toggle-btn[active] {
  background: rgba(137, 145, 255, 0.48);
}

.toggle-btn .toggle-thumb {
  position: relative;
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
  transition: all 0.2s ease-in-out;
}

.toggle-btn[active] .toggle-thumb {
  transform: translateX(32px);
  background: rgb(137 144 255);
}
</style>
