<template>
  <!-- <div id="div-gpt-ad-1740520192295-0" class="top-banner" :style="{ display: active ? 'block' : 'none' }"></div> -->
  <ins :class="`adsbygoogle top-banner${isMobile ? ' mobile' : ''}`"
     :style="{ display: active ? 'block' : 'none' }"
     data-ad-client="ca-pub-6659896098840763"
     :data-ad-slot="isMobile ? '1578092970' : '8479000094'"></ins>
  <!-- <ins class="adsbygoogle top-banner"
    :style="{ display: active ? 'block' : 'none' }"
    data-ad-client="ca-pub-6659896098840763"
    data-ad-slot="8479000094"
    data-ad-format="auto"
    data-full-width-responsive="true"></ins> -->
</template>

<script>
// import { sendEvent } from '../services/firebase';

export default {
  name: 'GamManager',
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isMobile: window.innerWidth <= 880,
      initialized: false,
      ready: false,
    };
  },
  watch: {
    active() {
      this.init();
    },
  },
  methods: {
    init() {
      if (!this.active) {
        return;
      }
      if (!this.initialized) {
        this.initialized = true;
        const script = document.createElement('script');
        script.async = true;
        script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6659896098840763';
        script.crossOrigin = 'anonymous';
        script.onload = () => {
          this.ready = true;
          this.toggleTopBanner();
          console.log('AdSense Auto Ads triggered');
        };
        script.onerror = () => {
          console.error('AdSense script failed to load');
        };
        document.head.appendChild(script);
      } else if (this.ready) {
        this.toggleTopBanner();
      }
    },
    showInterstitial() {
      // window.googletag.display(this.interstitialSlot);
    },
    toggleTopBanner() {
      if (this.active) {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
        console.log('SHOW+BANNER');
      }
    },
  },
  mounted() {
    window.addEventListener('show-video-modal', this.showInterstitial);
    setTimeout(() => {
      this.init();
    }, 1000);
  },
  beforeDestroy() {
    window.removeEventListener('show-video-modal', this.showInterstitial);
  },
};
</script>

<style scoped>
.top-banner {
  width: 728px;
  height: 90px;
  max-width: 100%;
  margin: 0 auto;
  display: block;
  text-align: center;
}
.top-banner.mobile {
  width: 300px;
  height: 50px;
}
.top-banner iframe {
  display: block;
  max-width: 100%;
}
</style>
