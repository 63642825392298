const CACHE_VERSION = '1.14.2';

export const getCache = (key) => {
  const cache = window.localStorage.getItem(key);
  if (!cache) {
    return null;
  }
  try {
    const { expiresAt, data, version } = JSON.parse(cache);
    if (expiresAt < Date.now() || version !== CACHE_VERSION) {
      return null;
    }
    return data;
  } catch (_) {
    return null;
  }
};

export const setCache = (key, data, expiresAfter) => {
  const cache = {
    data,
    expiresAt: Date.now() + (expiresAfter * 60000),
    version: CACHE_VERSION,
  };
  window.localStorage.setItem(key, JSON.stringify(cache));
};

export const deleteCache = (key) => {
  window.localStorage.removeItem(key);
};
