<template>
  <div class="ramp-container">
    <div class="video-modal" :enabled="videoEnabled">
      <div class="video-modal-content">
        <div id="pw-video-container" class="video-container"></div>
        <div class="video-controls" v-if="videoStarted > 0">
          <button v-if="timeLeft > 0" class="btn secondary" disabled>{{ t('Skip in', lang) }} {{ timeLeft }}s</button>
          <button v-else class="btn secondary" @click="skipVideo">{{ t('Skip', lang) }}</button>
          <button class="btn" @click="openSubscription">{{ t('Remove ads', lang) }}</button>
        </div>
        <div class="video-controls" v-else>
          {{ t('Loading ad...', lang) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { getSearchParam } from '../utils/search-params';

export default {
  name: 'RampManager',
  data() {
    return {
      rampLoaded: false,
      videoEnabled: false,
      videoStarted: 0,
      currentTime: 0,
    };
  },
  props: {
    pubId: {
      type: String,
      required: true,
    },
    websiteId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(['lang']),
    timeLeft() {
      return Math.ceil(((this.videoStarted + 10000) - this.currentTime) / 1000);
    },
  },
  watch: {
    $route() {
      this.refreshAd();
    },
  },
  methods: {
    refreshAd() {
      if (window.ramp) {
        window.ramp.que.push(() => {
          console.log('REFRESH AD', this.$route.path);
          window.ramp.spaNewPage(this.$route.path);
        });
      }
    },
    openSubscription() {
      window.dispatchEvent(new CustomEvent('open-subscription'));
    },
    initVideoAd() {
      window.Bolt.on('precontent_ad_video', window.Bolt.BOLT_AD_STARTED, () => {
        console.log('VIDEO_AD_STARTED');
      });

      window.Bolt.on('precontent_ad_video', window.Bolt.BOLT_AD_COMPLETE, () => {
        console.log('VIDEO_AD_COMPLETED');
        this.videoStarted = 0;
        this.videoEnabled = false;
      });

      window.Bolt.on('precontent_ad_video', window.Bolt.BOLT_AD_ERROR, () => {
        console.warn('VIDEO_AD_ERROR');
      });
    },
    initRamp() {
      if (!this.pubId || !this.websiteId) {
        console.warn('[RAMP] Missing pubId or websiteId');
        return;
      }

      if (window.ramp) {
        this.refreshAd();
        return;
      }

      window.ramp = window.ramp || {};
      window.ramp.que = window.ramp.que || [];
      window.ramp.passiveMode = true;
      window.ramp.onPlayerReady = this.initVideoAd;

      const script = document.createElement('script');
      script.src = `https://cdn.intergient.com/${this.pubId}/${this.websiteId}/ramp.js`;

      script.onload = () => {
        if (window.ramp) {
          window.ramp.que.push(() => {
            console.log('INIT AD', this.$route.path);
            window.ramp.spaNewPage(this.$route.path);
            this.rampLoaded = true;
            if (getSearchParam('testad') === '1') {
              setTimeout(this.showVideo, 1000);
            }
          });
        }
      };

      document.body.appendChild(script);
    },
    showVideo() {
      this.videoEnabled = true;
      this.videoStarted = Date.now();
      this.$nextTick(() => {
        window.ramp.que.push(() => {
          window.ramp.addUnits({ type: 'precontent_ad_video' }).then(window.ramp.displayUnits());
        });
      });
      console.log('VIDEO_AD_SHOW');
    },
    skipVideo() {
      window.ramp.destroyUnits('precontent_ad_video');
      this.videoStarted = 0;
      this.videoEnabled = false;
      console.log('VIDEO_AD_SKIP');
    },
  },
  mounted() {
    setTimeout(this.initRamp, 1000);
    window.addEventListener('show-video-modal', this.showVideo);
    setInterval(() => {
      this.currentTime = Date.now();
    }, 500);
  },
};
</script>

<style scoped>
.video-modal {
  position: fixed;
  display: none;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.8);
  padding-bottom: 64px;
}
.video-modal[enabled] {
  display: flex;
}
.video-modal .video-modal-content {
  position: relative;
  width: 1280px;
  height: 720px;
  max-width: 100vw;
  max-height: 56.25vw;
  background: #000;
}
.video-modal .video-controls {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  top: 100%;
  left: 0;
  right: 0;
  padding: 12px;
}
</style>
