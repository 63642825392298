<template>
  <div class="overlay" :active="opened" @click="close">
    <div class="modal" @click.stop v-if="reward">
      <div class="modal-header">
        <div class="modal-title">🏆 {{ t('Claim your reward!', lang) }}</div>
        <a @keypress.space="close" @click="close">
          <span class="material-symbols-outlined">close</span>
        </a>
      </div>
      <div class="modal-content">
        <div class="deck-container" v-if="deck">
          <DeckItem :deck="deck" hide-actions />
        </div>
        <div class="modal-actions">
          <button class="btn plain" @click="claimReward" :disabled="loading">{{ t('Claim', lang) }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
  collection, doc, getDoc, limit, onSnapshot, query, where,
} from 'firebase/firestore';
import { showError } from '../utils/error-handling';
import { firestore, sendEvent } from '../services/firebase';
import DeckItem from './DeckItem.vue';

export default {
  name: 'RewardModal',
  components: {
    DeckItem,
  },
  computed: {
    ...mapState(['client', 'lang', 'user']),
  },
  data() {
    return {
      opened: false,
      loading: false,
      reward: null,
      deck: null,
    };
  },
  methods: {
    ...mapActions(['showMessage']),
    getRewards() {
      this.rewardRef = onSnapshot(
        query(
          collection(firestore, 'rewards'),
          where('user_id', '==', this.user.id),
          where('claimed', '==', false),
          limit(1),
        ),
        async ({ docs, empty }) => {
          this.opened = !empty;
          const reward = docs[0]?.data() || null;
          if (reward && this.reward?.id !== reward.id && reward.type === 'deck') {
            const deckQuery = await getDoc(doc(firestore, `decks/${reward.reward_id}`));
            this.deck = deckQuery.exists() ? deckQuery.data() : null;
          } else {
            this.deck = null;
          }
          this.reward = reward;
        },
      );
    },
    async claimReward() {
      this.loading = true;
      try {
        const reward = { ...this.reward };
        await this.client.post(`/rewards/${reward.id}`);
        sendEvent('reward-claimed', { reward_id: reward.id });
        this.showMessage({
          type: 'success',
          message: this.t(reward.type === 'deck' ? 'Pack added to your collection!' : 'You have claimed a new reward!', this.lang),
        });
        this.loading = false;
        this.close();
      } catch (error) {
        showError(error);
        this.loading = false;
      }
    },
    close() {
      if (this.loading) {
        return;
      }
      this.deck = null;
      this.reward = null;
      this.opened = false;
    },
  },
  mounted() {
    this.getRewards();
  },
  beforeDestroy() {
    this.rewardRef?.();
  },
};
</script>

<style scoped>
.deck-container {
  width: 200px;
}

.modal-actions {
  display: flex;
  justify-content: center;
  margin-top: 32px;
}
</style>
