<template>
  <div class="overlay" :active="open" @click="closeDetails">
    <div class="modal" v-if="showOptions && deck" @click.stop>
      <div class="modal-header">
        <div class="modal-title">{{ t('Get the deck', lang) }}</div>
        <a @keypress.space="closeDetails" @click="closeDetails">
          <span class="material-symbols-outlined">close</span>
        </a>
      </div>
      <div class="modal-content">
        <div class="options">
          <div class="option" v-if="deck.subscriptions">
            <div class="option-name">💎 {{ t('Subscription', lang) }}</div>
            <div class="option-description">{{ t(`Get a ${deck.subscriptions.join('/')} subscription to get access to all expansion packs!`, lang) }}</div>
            <button class="btn highlight" @click="openSubscription">{{ t(user.subscribed ? 'Upgrade' : 'Subscribe', lang) }}</button>
          </div>
          <div class="option" v-if="deckPrice">
            <div class="option-name">💰 {{ t('Purchase', lang) }}</div>
            <div class="option-description">{{ t('Pay to get access to this deck forever!', lang) }}</div>
            <button class="btn highlight" @click="showOptions = false">{{ t('Buy', lang) }} {{ currencies[deckPrice.currency] + deckPrice.price }}</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" v-else-if="deck && paymentSecret && deckPrice" @click.stop>
      <div class="modal-header">
        <div class="modal-title">{{ t('Checkout', lang) }}</div>
        <a @keypress.space="closeDetails" @click="closeDetails">
          <span class="material-symbols-outlined">close</span>
        </a>
      </div>
      <div class="modal-content">
        <div class="form">
          <div class="item-data">Item: <b>{{ deck.title }}</b> ({{ deck.capacity }} {{ t('cards', lang) }})</div>
          <div class="item-data">Price: <b>{{ currencies[deckPrice.currency] + deckPrice.price }}</b></div>
          <StripeForm :item="deck.id" :payment-secret="paymentSecret" :product="deckPrice" :disabled="waitingTransaction" @payment-success="handlePayment" />
        </div>
      </div>
    </div>
    <div class="modal deck-modal" @click.stop v-else-if="deck">
      <div class="card-creator bulk" v-if="bulkImport">
        <h2>{{ t('Bulk Import', lang) }}</h2>
        <div class="card-type" v-if="!isFreeForm">
          <div class="label" :style="{ opacity: bulkImport.answer ? 0.5 : 1 }">{{ t('Prompts', lang) }}</div>
          <ToggleButton v-model="bulkImport.answer" :disabled="isImageDeck || loading" />
          <div class="label" :style="{ opacity: bulkImport.answer ? 1 : 0.5 }">{{ t('Answers', lang) }}</div>
        </div>
        <div class="card-content">
          <div class="card-preview">
            <textarea class="input" :placeholder="t('One card per line...', lang)" wrap="off" v-model="bulkImport.content"></textarea>
          </div>
          <div class="card-input" v-if="!bulkImport.answer && useBlanks">
            <div class="info-box">
              ℹ️
              <div>{{ t('To create a Bad Cards prompt, use "_" to add a blank to your prompt. You must add between 1 and 3 blanks to create your prompt!', lang) }}</div>
            </div>
          </div>
        </div>
        <div class="actions">
          <button class="btn ghost" @click="bulkImport = null" :disabled="loading">{{ t('Cancel', lang) }}</button>
          <button class="btn" @click="saveBulkImport" :disabled="loading || !bulkImport.content">{{ t('Import', lang) }}</button>
        </div>
      </div>
      <div class="card-creator" v-else-if="cardForm">
        <h2>{{ t('Create a card', lang) }}</h2>
        <div class="card-type" v-if="!isFreeForm">
          <div class="label" :style="{ opacity: cardForm.answer ? 0.5 : 1 }">{{ t('Prompt', lang) }}</div>
          <ToggleButton v-model="cardForm.answer" :disabled="isImageDeck || loading" />
          <div class="label" :style="{ opacity: cardForm.answer ? 1 : 0.5 }">{{ t('Answer', lang) }}</div>
        </div>
        <div class="card-content">
          <div class="card-preview">
            <CardItem :card="cardPreview" :cover="deck.cover" :mode="deck.type === 'custom' ? 'custom' : deck.game_modes[0]" />
          </div>
          <div class="card-input">
            <textarea class="input" :placeholder="t('Enter the content of your card here...', lang)" v-model="cardForm.content" maxlength="120"></textarea>
            <div class="info-box" v-if="!cardForm.answer && useBlanks">
              ℹ️
              <div>{{ t('To create a Bad Cards prompt, use "_" to add a blank to your prompt. You must add between 1 and 3 blanks to create your prompt!', lang) }}</div>
            </div>
            <button class="btn secondary" @click="cardForm = null; bulkImport = { content: '', answer: isImageDeck }">{{ t('Bulk Import', lang) }}</button>
          </div>
        </div>
        <div class="actions">
          <button class="btn ghost" @click="cardForm = null" :disabled="loading">{{ t('Cancel', lang) }}</button>
          <button class="btn" @click="saveCard" :disabled="loading || !cardForm.content">{{ t('Save', lang) }}</button>
        </div>
      </div>
      <div class="search-container" v-else-if="searchOpen" @scroll="onSearchScroll">
        <div class="search-input">
          <input v-model="searchTerms" :placeholder="t('Search for a card...', lang)" type="text" class="input" maxlength="255">
          <button class="btn" @click="openCardForm">{{ t('Create a card', lang) }}</button>
          <button class="btn secondary" @click="bulkImport = { content: '', answer: isImageDeck }">{{ t('Bulk import', lang) }}</button>
          <button class="btn ghost" @click="searchOpen = false">{{ t('Close', lang) }}</button>
        </div>
        <!-- <div class="tags search-tags">
          <a class="tag search-tag" :active="searchType === 'basic'" @click="selectSearchType('basic')">Free Decks</a>
          <a class="tag search-tag" :active="searchType === 'custom'" @click="selectSearchType('custom')">My Decks</a>
          <a class="tag search-tag" :active="searchType === 'favorite'" @click="selectSearchType('favorite')">Favorite Decks</a>
          <a class="tag search-tag" :active="searchType === 'paid'" @click="selectSearchType('paid')">Purchased Decks</a>
        </div> -->
        <div class="cards-container" v-if="Object.keys(searchCards).length">
          <div class="card" v-for="card in sortCards(searchCards).slice(0, searchPage * 24)" :key="card.id">
            <CardItem :card="card" :cover="deck.cover" :mode="deck.type === 'custom' ? 'custom' : deck.game_modes[0]" />
            <div class="card-actions">
              <button v-if="selectedCardIds.includes(card.id)" class="btn secondary small" @click="removeCard(card.id)">{{ t('Remove', lang) }}</button>
              <button v-else class="btn secondary small" @click="addCard(card)">{{ t('Add', lang) }}</button>
            </div>
          </div>
        </div>
        <div class="empty-state" v-else-if="!loadingSearch">
          {{ t('You have not created any cards yet.', lang) }}
        </div>
      </div>
      <div class="cards-container" v-else>
        <div v-if="edit" class="card new-card" @click="openSearch">
          <div class="card-container">
            <div class="card-content">
              <b>{{ t('Looking for cards?', lang) }}</b>
              <button class="btn small" @click="openSearch">{{ t('Browse cards', lang) }}</button>
              <button class="btn secondary small" @click="openCardForm">{{ t('Create a card', lang) }}</button>
              <button class="btn highlight small" @click="openSubscription">{{ t('Get more cards', lang) }}</button>
            </div>
          </div>
        </div>
        <div class="card" v-for="card in sortedCards" :key="card.id">
          <CardItem :card="card" :cover="deck.cover" :mode="deck.type === 'custom' ? 'custom' : deck.game_modes[0]" />
          <div class="card-actions" v-if="edit">
            <button class="btn secondary small" @click="removeCard(card.id)">{{ t('Remove', lang) }}</button>
          </div>
        </div>
      </div>
      <div class="info-panel">
        <div class="info-header">
          <a @keypress.space="closeDetails" @click="closeDetails">
            <span class="material-symbols-outlined">close</span>
          </a>
        </div>
        <div v-if="edit">
          <div class="form">
            <div class="field">
              <div class="field-label">{{ t('Deck title', lang) }}</div>
              <div class="field-group">
                <input type="text" maxlength="40" class="input" :placeholder="t('Deck title', lang)" :disabled="loading" v-model="deckForm.title">
                <DropdownButton :items="languageItems" width="100%" :selected="deckForm.language" @change="deckForm.language = $event.value" :disabled="loading">
                  {{ deckForm.language }}
                </DropdownButton>
              </div>
            </div>
            <div class="field">
              <div class="field-label">{{ t('Cards', lang) }}</div>
              <div class="field-value"><b>{{ selectedCardIds.length }}/{{ deck.capacity }}</b> {{ t('cards', lang) }}</div>
            </div>
          </div>
          <div class="deck-actions">
            <button class="btn" @click="updateDeck" :disabled="loading">💾 {{ t('Save', lang) }}</button>
            <button class="btn secondary" @click="closeEdit" :disabled="loading">❌ {{ t('Cancel', lang) }}</button>
          </div>
        </div>
        <div v-else>
          <div class="deck-title">{{ deck.title }}</div>
          <div class="deck-author">by <b>{{ deck.user.name || t('Unknown', lang) }}</b></div>
          <div class="deck-cards">{{ deck.card_ids.length }} {{ t('cards', lang) }}</div>
          <div class="deck-tags">
            <div class="tag language">{{ deck.language || 'English' }}</div>
            <div class="tag mode" v-for="mode in deck.game_modes" :key="mode">{{ gameModeNames[mode] || mode }}</div>
          </div>
          <div class="deck-actions">
            <button v-if="!isAvailable && deck.subscriptions" class="btn highlight" @click="openSubscription" :disabled="loading">{{ t(user.subscribed ? 'Upgrade' : 'Subscribe', lang) }}</button>
            <button v-if="!isAvailable && deck.product" class="btn highlight" @click="getPaymentSecret(deck.id)" :disabled="loading">💰 {{ t('Buy', lang) }} {{ currencies[deck.product.currency] + (deck.product.price - (user.subscribed ? deck.product.price * 0.15 : 0)).toFixed(2) }}</button>
            <button v-else-if="!preview && (deck.type !== 'reward' || purchased)" class="btn" @click="createGame(deck.game_modes[0], deck)" :disabled="!deck.card_ids.length || loading">{{ t('Start a game', lang) }}</button>
            <button v-if="deck.user.id === user.id" class="btn secondary" @click="editDeck()" :disabled="loading">✏️ {{ t('Edit', lang) }}</button>
            <button class="btn secondary" @click="toggleFavorite(!deck.favorite)" :disabled="loading">{{ t(deck.favorite ? '💔 Unfavorite' : '❤️ Favorite', lang) }}</button>
            <button v-if="!preview" class="btn secondary" @click="duplicateDeck" :disabled="loading">📋 {{ t('Duplicate', lang) }}</button>
            <button v-if="deck.user.id === user.id" class="btn ghost" @click="deleteDeck" :disabled="loading">🗑️ {{ t('Delete', lang) }}</button>
            <div class="deck-share">
              <div>{{ t('Share this pack', lang) }}</div>
              <div class="deck-share-buttons">
                <button class="btn small" @click="copyLink" :disabled="loading || linkCopied">{{ t(linkCopied ? 'Copied!' : 'Share pack', lang) }}</button>
                <button v-if="deck.type === 'custom'" class="btn small" @click="copyCode" :disabled="loading || codeCopied">{{ t(codeCopied ? 'Copied!' : 'Copy pack code', lang) }}</button>
              </div>
            </div>
          </div>
          <div class="deck-stats">
            <div class="deck-stat">Played <b>{{ deck.game_count || 0 }}</b> times</div>
            <div class="deck-stat">Duplicated <b>{{ deck.duplicate_count || 0 }}</b> times</div>
            <div class="deck-stat">Favorite <b>{{ deck.favorite_count || 0 }}</b> times</div>
            <div class="deck-stat" v-if="deck.type === 'paid' && deck.product">Purchased <b>{{ deck.purchase_count || 0 }}</b> times</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
  collection, getCountFromServer, getDocs, limit, onSnapshot, orderBy, query, where,
} from 'firebase/firestore';
import { firestore, sendEvent } from '../services/firebase';
import CardItem from './CardItem.vue';
import DropdownButton from './DropdownButton.vue';
import StripeForm from './StripeForm.vue';
import ToggleButton from './ToggleButton.vue';

export default {
  name: 'DeckModal',
  data() {
    return {
      deck: null,
      open: false,
      edit: false,
      preview: false,
      searchOpen: false,
      bulkImport: false,
      showOptions: false,
      codeCopied: false,
      linkCopied: false,
      loading: false,
      loadingSearch: false,
      searchTerms: '',
      searchType: 'custom',
      searchPage: 1,
      searchDecks: [],
      deckForm: null,
      purchased: null,
      paymentSecret: null,
      deckPrice: null,
      waitingTransaction: false,
      cardForm: null,
      scrolled: false,
    };
  },
  components: {
    CardItem,
    DropdownButton,
    StripeForm,
    ToggleButton,
  },
  computed: {
    ...mapState(['user', 'client', 'lang', 'languages', 'currencies', 'gameModeNames']),
    isAnonymous() {
      return this.user.type === 'anonymous';
    },
    isAvailable() {
      if (!this.deck) {
        return false;
      }
      return ['basic', 'custom'].includes(this.deck.type) || this.purchased || (this.deck.subscriptions && this.deck.subscriptions.includes(this.user.subscription_type));
    },
    isFreeForm() {
      if (!this.deck) {
        return false;
      }
      return !!this.deck.game_modes.find((mode) => ['free-form', '3words', 'downtown', 'blanks'].includes(mode));
    },
    isImageDeck() {
      return !!this.deck.game_modes.find((mode) => ['gifs', 'memes'].includes(mode));
    },
    searchCards() {
      const terms = this.searchTerms.trim().toLowerCase();
      return this.searchDecks.reduce((cards, deck) => {
        Object.values(deck.cards).forEach((card) => {
          if (terms.length < 3 || card.card.content.trim().toLowerCase().includes(terms)) {
            cards[card.card.id] = card.card;
          }
        });
        return cards;
      }, {});
    },
    useBlanks() {
      if (!this.deck) {
        return false;
      }
      const blankModes = ['standard', 'girls', 'kids'];
      return !!this.deck.game_modes.find((mode) => blankModes.includes(mode));
    },
    sortedCards() {
      if (this.edit) {
        return this.deckForm.cards;
      }
      const cards = this.sortCards(this.deck.cards, true);
      if (window.innerWidth <= 480) {
        return cards.slice(0, 30);
      }
      return cards;
    },
    selectedCardIds() {
      if (!this.deckForm) {
        return [];
      }
      return this.deckForm.cards.map((card) => card.id);
    },
    languageItems() {
      return this.languages.map(({ code: value }) => ({
        label: value,
        value,
      }));
    },
    cardPreview() {
      if (!this.cardForm) {
        return null;
      }
      const { answer } = this.cardForm;
      let { content } = this.cardForm;
      let match = [];
      if (this.useBlanks && !answer) {
        content = this.cardForm.content.replace(/_+/g, '_');
        match = this.cardForm.content.matchAll(/_+/g);
      }
      return {
        type: answer ? 'answer' : 'prompt',
        content_type: 'text',
        content,
        blank_cursors: [...match].map((m) => m.index),
      };
    },
  },
  watch: {
    searchCards() {
      this.searchPage = 1;
    },
  },
  methods: {
    ...mapActions(['openAuth', 'showMessage', 'onFavoriteChange']),
    sortCards(cards, reMap) {
      let result = Object.values(cards);
      if (reMap) {
        result = result.map((card) => card.card);
      }
      return result.sort((a, b) => b.type.localeCompare(a.type));
    },
    openCardForm() {
      this.cardForm = {
        answer: this.isImageDeck,
        content: '',
      };
    },
    onSearchScroll(event) {
      if (this.scrolled) {
        return;
      }
      const el = event.target;
      if (Math.ceil(el.scrollTop) >= Math.floor(el.scrollHeight - el.clientHeight)) {
        this.scrolled = true;
        this.searchPage += 1;
        this.$nextTick(() => {
          this.scrolled = false;
        });
      }
    },
    openSubscription() {
      window.dispatchEvent(new CustomEvent('open-subscription'));
      this.closeDetails();
    },
    closeDetails() {
      if (this.loading) {
        return;
      }
      this.closeEdit();
      this.open = false;
      this.deck = null;
      this.showOptions = false;
      this.paymentSecret = null;
    },
    closeEdit() {
      this.searchOpen = false;
      this.edit = false;
      this.cardForm = null;
      this.bulkImport = null;
    },
    editDeck(search) {
      const { title, language } = this.deck;
      this.deckForm = {
        title,
        language,
        cards: [...this.sortedCards],
      };
      this.edit = true;
      if (search) {
        this.openSearch();
      }
    },
    copyCode() {
      navigator.clipboard.writeText(this.deck.id);
      this.codeCopied = true;
      setTimeout(() => {
        this.codeCopied = false;
      }, 1000);
      sendEvent('deck-code-copied', { deck_id: this.deck.id });
    },
    copyLink() {
      navigator.clipboard.writeText(`${window.location.origin}/?pack_code=${this.deck.id}`);
      this.linkCopied = true;
      setTimeout(() => {
        this.linkCopied = false;
      }, 1000);
      sendEvent('share-deck', { deck_id: this.deck.id });
    },
    addCard(card) {
      this.deckForm = {
        ...this.deckForm,
        cards: [card, ...this.deckForm.cards],
      };
    },
    removeCard(cardId) {
      this.deckForm = {
        ...this.deckForm,
        cards: this.deckForm.cards.filter((card) => card.id !== cardId),
      };
    },
    selectSearchType(type) {
      this.searchType = type;
      this.getDecks();
    },
    openSearch() {
      this.getDecks();
      this.searchOpen = true;
    },
    async getDecks() {
      this.loadingSearch = true;
      this.searchDecks = [];
      this.searchType = this.isImageDeck ? 'basic' : 'custom';
      if (this.searchType === 'custom') {
        const deckQuery = query(
          collection(firestore, 'decks'),
          where('type', '==', 'custom'),
          where('game_modes', 'array-contains-any', this.deck.game_modes),
          where('user.id', '==', this.user.id),
          orderBy('created_at', 'desc'),
          limit(15),
        );
        const result = await getDocs(deckQuery);
        this.searchDecks = result.docs.map((doc) => doc.data());
      } else if (this.searchType === 'favorite') {
        const favoritesQuery = query(
          collection(firestore, 'favorites'),
          where('user_id', '==', this.user.id),
          orderBy('created_at', 'desc'),
          limit(30),
        );
        const favorites = await getDocs(favoritesQuery);
        const deckQuery = query(
          collection(firestore, 'decks'),
          where('id', 'in', favorites.docs.map((doc) => doc.get('deck_id'))),
          where('game_modes', 'array-contains-any', this.deck.game_modes),
          orderBy('created_at', 'desc'),
          limit(10),
        );
        const result = await getDocs(deckQuery);
        this.searchDecks = result.docs.map((doc) => doc.data());
      } else {
        const deckQuery = query(
          collection(firestore, 'decks'),
          this.isImageDeck ? where('type', 'in', ['basic', 'custom']) : where('type', '==', this.searchType),
          where('game_modes', 'array-contains-any', this.deck.game_modes),
          orderBy('created_at', 'desc'),
          limit(15),
        );
        const result = await getDocs(deckQuery);
        this.searchDecks = result.docs.map((doc) => doc.data());
      }
      this.loadingSearch = false;
    },
    async getFavorite() {
      if (this.preview) {
        this.loading = true;
        const favorites = await getCountFromServer(
          query(
            collection(firestore, 'favorites'),
            where('user_id', '==', this.user.id),
            where('deck_id', '==', this.deck.id),
          ),
        );
        this.deck = {
          ...this.deck,
          favorite: favorites.data().count > 0,
        };
        this.loading = false;
      }
    },
    async getPaymentSecret(deckId) {
      if (this.isAnonymous) {
        this.openAuth();
        return;
      }
      if (this.loading) {
        return;
      }
      this.loading = true;
      try {
        const payment = await this.client.post(`/decks/${deckId}/payment-intents`);
        this.paymentSecret = payment.payment_secret;
        this.deckPrice = {
          price: (payment.amount / 100).toFixed(2),
          currency: payment.currency,
        };
        sendEvent('deck-payment-opened', { deck: deckId });
        this.open = true;
      } catch (error) {
        console.warn(error);
      }
      this.loading = false;
    },
    handlePayment() {
      this.waitingTransaction = true;
      const snapshot = onSnapshot(
        query(
          collection(firestore, 'transactions'),
          where('user_id', '==', this.user.id),
          where('item_id', '==', this.deck.id),
          limit(1),
        ),
        (res) => {
          if (!res.empty) {
            this.purchased = true;
            this.waitingTransaction = false;
            this.open = false;
            this.paymentSecret = null;
            window.dispatchEvent(new CustomEvent('deck-purchased', { detail: { deckId: this.deck.id } }));
            this.showMessage({
              type: 'success',
              message: 'You have successfully purchased a new pack!',
            });
            sendEvent('deck-purchased', { deck: this.deck.id });
            snapshot();
          }
        },
      );
    },
    async createGame(mode, deck) {
      if (this.isAnonymous) {
        this.openAuth();
        return;
      }
      if (this.loading) {
        return;
      }
      this.loading = true;
      try {
        const settings = {
          name: `${this.user.name || 'Unknown'}'s game`,
          mode,
          decks: deck ? [deck.id] : null,
          is_manual: true,
        };
        const game = await this.client.post('/games', settings);
        settings.id = game.id;
        sendEvent('create-game', settings);
        this.closeDetails();
        this.$router.push(`/g/${game.id}`);
      } catch (error) {
        console.warn(error);
      }
      this.loading = false;
    },
    async duplicateDeck() {
      if (this.isAnonymous) {
        this.openAuth();
        return;
      }
      if (this.loading) {
        return;
      }
      this.loading = true;
      try {
        const deck = await this.client.post(`/decks/${this.deck.id}/duplicates`);
        deck.favorite = false;
        this.deck = deck;
        sendEvent('duplicate-deck', { deck_id: deck.id });
      } catch (error) {
        console.warn(error);
      }
      this.loading = false;
    },
    async saveCard() {
      if (this.isAnonymous) {
        this.openAuth();
        return;
      }
      if (this.loading) {
        return;
      }
      this.loading = true;
      try {
        const card = await this.client.post('/cards', {
          ...this.cardPreview,
          add_to_deck: this.deck.id,
        });
        this.deck = {
          ...this.deck,
          cards: {
            [card.id]: { card, count: 1 },
            ...this.deck.cards,
          },
          card_ids: [...this.deck.card_ids, card.id],
        };
        this.closeEdit();
        sendEvent('create-card', { card_id: card.id });
        window.dispatchEvent(new CustomEvent('deck-updated', { detail: { deck: this.deck.id } }));
      } catch (error) {
        console.warn(error);
      }
      this.loading = false;
    },
    async saveBulkImport() {
      if (this.isAnonymous) {
        this.openAuth();
        return;
      }
      if (this.loading) {
        return;
      }
      this.loading = true;
      try {
        const { deck } = await this.client.post('/cards/bulk-import', {
          ...this.bulkImport,
          type: this.bulkImport.answer ? 'answer' : 'prompt',
          add_to_deck: this.deck.id,
        });
        this.deck = deck;
        this.closeEdit();
        sendEvent('import-cards', { deck_id: deck.id });
        window.dispatchEvent(new CustomEvent('deck-updated', { detail: { deck: deck.id } }));
      } catch (error) {
        console.warn(error);
      }
      this.loading = false;
    },
    async deleteDeck() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      try {
        const deck = await this.client.delete(`/decks/${this.deck.id}`);
        sendEvent('delete-deck', { deck_id: deck.id });
        this.loading = false;
        this.closeDetails();
        window.dispatchEvent(new CustomEvent('deck-deleted', { detail: { deck: deck.id } }));
      } catch (error) {
        console.warn(error);
      }
      this.loading = false;
    },
    async updateDeck() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      try {
        const { title, language, cards } = this.deckForm;
        const deck = await this.client.post(`/decks/${this.deck.id}`, {
          title,
          language,
          cards: cards.map((card) => card.id),
        });
        deck.favorite = this.deck.favorite;
        this.deck = deck;
        this.closeEdit();
        sendEvent('update-deck', { deck_id: deck.id });
        window.dispatchEvent(new CustomEvent('deck-updated', { detail: { deck: deck.id } }));
      } catch (error) {
        console.warn(error);
      }
      this.loading = false;
    },
    async toggleFavorite(favorite) {
      if (this.isAnonymous) {
        this.openAuth();
        return;
      }
      if (this.loading) {
        return;
      }
      this.loading = true;
      try {
        if (favorite) {
          await this.client.post(`/decks/${this.deck.id}/favorites`);
          sendEvent('favorite-deck', { deck_id: this.deck.id });
        } else {
          await this.client.delete(`/decks/${this.deck.id}/favorites`);
          sendEvent('unfavorite-deck', { deck_id: this.deck.id });
        }
        this.onFavoriteChange({
          deckId: this.deck.id,
          favorite,
        });
      } catch (error) {
        console.warn(error);
      }
      this.loading = false;
    },
    async setDeck(event) {
      const {
        deck,
        purchased,
        openPayment,
        preview,
        openEdit,
      } = event.detail;
      this.deck = null;
      this.open = !!deck;
      this.purchased = typeof purchased === 'boolean' ? purchased : null;
      this.preview = preview === true;
      if (openPayment && deck) {
        this.showOptions = true;
        if (deck.product) {
          await this.getPaymentSecret(deck.id);
        }
      }
      this.deck = deck || null;
      if (openEdit) {
        this.editDeck(true);
      }
      this.getFavorite();
    },
    handleFavoriteChange(event) {
      const { deckId, favorite } = event.detail;
      if (this.deck?.id === deckId) {
        this.deck = {
          ...this.deck,
          favorite,
        };
      }
    },
  },
  mounted() {
    window.addEventListener('open-deck', this.setDeck);
    window.addEventListener('favorite-change', this.handleFavoriteChange);
  },
  beforeDestroy() {
    window.removeEventListener('open-deck', this.setDeck);
    window.removeEventListener('favorite-change', this.handleFavoriteChange);
  },
};
</script>

<style scoped>
.deck-info {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 16px;
  background: #5f51d7;
  background: linear-gradient(0deg, rgba(95,81,215,0.8) 50%, rgba(95,81,215,0) 100%);
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  z-index: 1;
  will-change: transform;
}
.deck-title {
  font-weight: bold;
}
.deck-author,
.deck-cards {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.64);
}
.deck-cards {
  margin-top: 4px;
  margin-bottom: 16px;
}
.deck-tags {
  display: flex;
  gap: 4px 8px;
  flex-wrap: wrap;
}
.deck-tags .tag {
  font-size: 12px;
  background: rgb(137 144 255);
  padding: 2px 6px;
  border-radius: 12px;
  text-transform: capitalize;
}
.deck-description {
  font-size: 14px;
}
.deck-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  gap: 4px;
}
.deck-share {
  font-size: 14px;
  font-weight: 500;
  margin-top: 16px;
}
.deck-share .deck-share-buttons {
  display: flex;
  gap: 4px 8px;
  flex-wrap: wrap;
  margin-top: 8px;
}

.modal.deck-modal {
  display: flex;
  width: 1440px;
  height: 800px;
  max-height: 90vh;
  max-width: 100%;
  padding: 0;
  border-radius: 8px;
  overflow: hidden;
}
.modal.deck-modal .deck-actions {
  flex-direction: column;
  align-items: stretch;
  gap: 12px;
}
.modal.deck-modal .deck-stats {
  color: rgba(255, 255, 255, 0.64);
  margin-top: 24px;
  font-size: 14px;
}
.modal.deck-modal .deck-title {
  font-size: 18px;
  margin-bottom: 4px;
}
.modal .options {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
}
.modal .option {
  display: flex;
  flex-direction: column;
  width: calc(50% - 8px);
  padding: 12px 16px;
  background-color: #000;
  border-radius: 8px;
  font-size: 14px;
}
.modal .option .option-name {
  font-size: 18px;
  font-weight: 600;
}
.modal .option .option-description {
  margin: 12px 0 16px;
  flex-grow: 1;
}

.empty-state {
  padding: 16px 24px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.16);
  text-align: center;
  margin: 24px;
}

.search-container,
.cards-container,
.card-creator {
  position: relative;
  flex-grow: 1;
  overflow: auto;
  background-color: #1e1e1e;
}
.cards-container {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  padding: 24px;
  align-items: flex-start;
  align-content: flex-start;
}
.cards-container .card {
  position: relative;
  width: calc(25% - 9px);
}
.cards-container .card.new-card {
  border: 1px solid;
  border-radius: 16px;
}
.cards-container .card.new-card .card-container {
  position: relative;
  padding-top: 140%;
}
.cards-container .card.new-card .card-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  text-align: center;
  gap: 12px;
}
.cards-container .card .card-actions {
  position: absolute;
  bottom: 12px;
  right: 12px;
}

.card-creator {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  padding: 24px;
  gap: 24px;
}
.card-creator.bulk {
  justify-content: flex-start;
}
.card-creator .card-type,
.card-creator .actions {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}
.card-creator .card-content {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  width: 640px;
  max-width: 100%;
}
.card-creator.bulk .card-content {
  flex-grow: 1;
  width: 100%;
}
.card-creator.bulk .card-content .input {
  height: 100%;
}
.card-creator .card-content .card-preview {
  flex-grow: 1;
}
.card-creator .card-content .card-input {
  width: calc(50% - 24px);
}
.card-creator.bulk .card-content .card-input {
  width: 400px;
}
.card-creator.bulk .card-content .card-preview {
  height: 100%;
  min-height: 320px;
}
.card-creator .info-box {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  padding: 12px;
  background: rgba(0, 0, 0, 0.48);
  border-radius: 8px;
  font-size: 14px;
  margin: 12px 0;
}
.card-creator .info-box div {
  flex-grow: 1;
}

.search-container .search-input {
  display: flex;
  gap: 12px;
  padding: 24px 24px 0;
  margin-bottom: 12px;
}
.search-container .search-input .input {
  flex-grow: 1;
}
.search-container .search-tags {
  padding: 0 24px;
}
.search-container .search-tags .tag[active] {
  background: #fff;
  color: #5f51d7;
}

.info-panel {
  width: 300px;
  padding: 16px 24px;
  flex-shrink: 0;
}
.info-panel .info-header {
  text-align: right;
  margin-bottom: 12px;
}

.form .item-data {
  margin-bottom: 8px;
}

@media screen and (max-width: 1170px) {
  .cards-container .card {
    width: calc(33.333333% - 8px);
  }
}

@media screen and (max-width: 980px) {
  .cards-container .card {
    width: calc(50% - 6px);
  }
}

@media screen and (max-width: 880px) {
  .card-creator {
    justify-content: flex-start;
  }
  .card-creator .card-content {
    flex-flow: column;
  }
  .card-creator .card-content .card-preview,
  .card-creator .card-content .card-input {
    width: 100%;
  }
}

@media screen and (max-width: 800px) {
  .modal.deck-modal {
    flex-direction: column-reverse;
    height: auto;
    max-height: none;
    justify-content: flex-start;
  }
  .cards-container .card {
    width: calc(50% - 6px);
  }
  .info-panel {
    width: auto;
  }
  .search-container,
  .cards-container {
    flex-grow: 0;
  }
  .overlay {
    align-items: flex-start;
  }
}

@media screen and (max-width: 580px) {
  .modal .option {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .cards-container .card {
    width: 100%;
  }
  .cards-container {
    display: none;
  }
}
</style>
