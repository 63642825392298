<template>
  <div class="page home">
    <div class="page-container">
      <div class="container">
        <div class="game-menu">
          <div class="buttons">
            <form class="input-field" @submit.prevent="joinGame">
              <input id="code" autocapitalize="characters" type="text" maxlength="7" placeholder="Join with game code" class="input code-input" v-model="gameCode" :disabled="loading">
              <button type="submit" class="btn icon ghost" :disabled="loading || gameCode.length !== 7">
                <span class="material-symbols-outlined">send</span>
              </button>
            </form>
            <router-link class="btn plain" to="/games" :disabled="loading">{{ t('Browse games', lang) }}</router-link>
            <button class="btn" @click="openGameForm" :disabled="loading">{{ t('Create a game', lang) }}</button>
          </div>
          <div class="streams" v-if="streamers.length">
            <div class="streams-title">
              <img class="icon" src="/assets/twitch.svg" alt="Twitch">
              {{ t('Twitch Streams', lang) }}
            </div>
            <div class="streams-list">
              <a class="stream" v-for="streamer in streamers" :href="`https://twitch.tv/${streamer.name}`" :key="streamer.id" target="_blank" rel="noopener noreferrer">
                <img :src="streamer.avatar" :alt="streamer.display_name">
                <div class="stream-name">{{ streamer.display_name }}</div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="page-sections">
        <section class="section">
          <div class="wrapper">
            <div class="top-slider swiper" ref="topSwiper">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <button class="banner-slide" @click="user.subscribed ? $router.push('/shop') : openSubscription()">
                    <img src="@/assets/free-trial-banner.png" alt="15,000 Premium cards - No ads - Unlimited custom packs - and more!">
                  </button>
                </div>
                <div class="swiper-slide">
                  <button class="banner-slide" @click="user.subscribed ? $router.push('/packs/custom') : openSubscription()">
                    <img src="@/assets/custom-packs-banner.png" alt="Subscribe to create custom packs!">
                  </button>
                </div>
                <div class="swiper-slide">
                  <button class="banner-slide" @click="$router.push('/referral-program')">
                    <img src="@/assets/referral-program-banner.png" alt="Join our referral program today!">
                  </button>
                </div>
                <div class="swiper-slide">
                  <a class="banner-slide" href="https://numworlds.com?utm_campaign=badcards_banner&utm_source=badcards" target="_blank" rel="noopener noreferrer">
                    <img src="@/assets/numworlds-banner.jpg" alt="Download NumWorlds on iOS and Android!">
                  </a>
                </div>
                <div class="swiper-slide">
                  <button class="banner-slide" @click="user.subscribed ? $router.push('/shop') : openSubscription()">
                    <img src="@/assets/free-trial-banner-3.jpg" alt="Start your free trial today!">
                  </button>
                </div>
                <div class="swiper-slide">
                  <button class="banner-slide" @click="$router.push('/referral-program')">
                    <img src="@/assets/referral-program-banner-2.png" alt="Get revenue from your community by joining our partner program!">
                  </button>
                </div>
              </div>
              <div class="swiper-controls">
                <div class="swiper-arrow-prev">
                  <span class="material-symbols-outlined">arrow_back_ios</span>
                </div>
                <div class="swiper-pagination"></div>
                <div class="swiper-arrow-next">
                  <span class="material-symbols-outlined">arrow_forward_ios</span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="section" v-if="newGames.length">
          <div class="wrapper">
            <h2 class="section-title">✨ {{ t('New Games', lang) }}</h2>
            <div class="game-cards">
              <div :class="`game-card ${gameMode.id}`" :style="{ backgroundImage: gameMode.background ? `url(${gameMode.background})` : `url(/assets/${gameMode.id}-tile.png)` }" v-for="gameMode in newGames" :key="gameMode.id">
                <div class="game-card-details">
                  <div class="game-card-title">{{ gameMode.icon }} {{ gameMode.title }}</div>
                  <div class="game-card-description">{{ gameMode.description }}</div>
                  <div class="game-card-actions">
                    <router-link class="btn plain small" :to="`/games/${gameMode.id}`" :disabled="loading">{{ t('Browse games', lang) }}</router-link>
                    <button class="btn small" @click="createGame(gameMode.id, gameMode.decks)" :disabled="loading">{{ t('Play', lang) }}</button>
                  </div>
                </div>
                <img v-if="gameMode.new" src="@/assets/new-game-icon.png" alt="" class="game-card-icon">
              </div>
            </div>
          </div>
        </section>
        <section class="section">
          <div class="wrapper">
            <div class="section-header">
              <h2 class="section-title">🎮 {{ t('Featured Games', lang) }}</h2>
              <router-link class="link" to="/game-list">{{ t('View more', lang) }}</router-link>
            </div>
            <div class="game-cards">
              <div :class="`game-card ${gameMode.id}`" :style="{ backgroundImage: gameMode.background ? `url(${gameMode.background})` : `url(/assets/${gameMode.id}-tile.png)` }" v-for="(gameMode, index) in featuredGames" :key="gameMode.id + index">
                <div class="game-card-details">
                  <div class="game-card-title">{{ gameMode.icon }} {{ gameMode.title }}</div>
                  <div class="game-card-description">{{ gameMode.description }}</div>
                  <div class="game-card-actions">
                    <router-link class="btn plain small" :to="`/games/${gameMode.id}`" :disabled="loading">{{ t('Browse games', lang) }}</router-link>
                    <button class="btn small" @click="createGame(gameMode.id, gameMode.decks)" :disabled="loading">{{ t('Play', lang) }}</button>
                  </div>
                </div>
                <img v-if="gameMode.new" src="@/assets/new-game-icon.png" alt="" class="game-card-icon">
              </div>
            </div>
          </div>
        </section>
        <section class="section">
          <div class="wrapper">
            <div class="section-header">
              <h2 class="section-title">🎮 {{ t('Popular Games', lang) }}</h2>
              <router-link class="link" to="/game-list">{{ t('View more', lang) }}</router-link>
            </div>
            <div class="game-cards">
              <div :class="`game-card ${gameMode.id}`" :style="{ backgroundImage: gameMode.background ? `url(${gameMode.background})` : `url(/assets/${gameMode.id}-tile.png)` }" v-for="gameMode in popularGames" :key="gameMode.id">
                <div class="game-card-details">
                  <div class="game-card-title">{{ gameMode.icon }} {{ gameMode.title }}</div>
                  <div class="game-card-description">{{ gameMode.description }}</div>
                  <div class="game-card-actions">
                    <router-link class="btn plain small" :to="`/games/${gameMode.id}`" :disabled="loading">{{ t('Browse games', lang) }}</router-link>
                    <button class="btn small" @click="createGame(gameMode.id, gameMode.decks)" :disabled="loading">{{ t('Play', lang) }}</button>
                  </div>
                </div>
                <img v-if="gameMode.new" src="@/assets/new-game-icon.png" alt="" class="game-card-icon">
              </div>
            </div>
          </div>
        </section>
        <section class="section">
          <div class="wrapper">
            <button class="banner" @click="user.subscribed ? $router.push('/shop') : openSubscription()">
              <img src="@/assets/subscription-banner.png" alt="Subscribe to get access to more than 15,000 cards!">
            </button>
          </div>
        </section>
        <section class="section" v-if="popularDecks.length">
          <div class="wrapper">
            <div class="section-header">
              <h2 class="section-title">❤️ Popular Packs</h2>
              <router-link class="link" to="/packs">{{ t('View more', lang) }}</router-link>
            </div>
            <div class="deck-list-container">
              <DeckList :decks="popularDecks" :options="deckListOptions" />
            </div>
          </div>
        </section>
        <section class="section" v-if="featuredDecks.length">
          <div class="wrapper">
            <div class="section-header">
              <h2 class="section-title">⭐️ Featured Packs</h2>
              <router-link class="link" to="/packs">{{ t('View more', lang) }}</router-link>
            </div>
            <div class="deck-list-container">
              <DeckList :decks="featuredDecks" :options="deckListOptions" />
            </div>
          </div>
        </section>
        <section class="section" v-if="freeDecks.length">
          <div class="wrapper">
            <div class="section-header">
              <h2 class="section-title">🤘 Free Packs</h2>
              <router-link class="link" to="/packs/basic">{{ t('View more', lang) }}</router-link>
            </div>
            <div class="deck-list-container">
              <DeckList :decks="freeDecks" :options="deckListOptions" />
            </div>
          </div>
        </section>
        <section class="section">
          <div class="wrapper">
            <div class="tiles">
              <router-link class="tile" to="/packs/custom">
                <img src="@/assets/game-night-banner.png" alt="Game night with custom packs!">
              </router-link>
              <router-link class="tile" to="/referral-program">
                <img src="@/assets/creator-partnerships-banner.png" alt="Join our creator & partner program today!">
              </router-link>
            </div>
          </div>
        </section>
        <!-- <section class="section" v-for="(mode, index) in gameModes" :key="mode.id">
          <div class="wrapper" v-if="gameModeDecks[index]?.length">
            <h2 class="section-title">{{ mode.icon }} {{ mode.title }}</h2>
            <div class="deck-list-container">
              <DeckList :decks="gameModeDecks[index]" :options="deckListOptions" />
            </div>
          </div>
        </section> -->
        <section class="section">
          <div class="wrapper">
            <div class="faq">
              <h2>{{ t('Got Questions?', lang) }}</h2>
              <button :class="`item${index === selectedFaq ? ' active' : ''}`" v-for="(item, index) in faq" @click="toggleFaq(index)" :key="item[0]">
                <div class="question">{{ t(item[0], lang) }}</div>
                <div class="answer" ref="answers" v-html="t(item[1], lang)"></div>
                <span class="material-symbols-outlined">arrow_forward_ios</span>
              </button>
            </div>
          </div>
        </section>
      </div>
    </div>
    <PageFooter />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
  collection, getDocs, limit, orderBy, query, where,
} from 'firebase/firestore';
import Swiper from 'swiper';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
// eslint-disable-next-line import/extensions
import 'swiper/css';
// eslint-disable-next-line import/extensions
import 'swiper/css/autoplay';
// eslint-disable-next-line import/extensions
import 'swiper/css/navigation';
// eslint-disable-next-line import/extensions
import 'swiper/css/pagination';
import { firestore, sendEvent } from '../services/firebase';
import DeckList from './DeckList.vue';
import PageFooter from './PageFooter.vue';
import { showError } from '../utils/error-handling';

export default {
  name: 'HomePage',
  data() {
    return {
      loading: false,
      loadingDecks: false,
      streamers: [],
      freeDecks: [],
      popularDecks: [],
      featuredDecks: [],
      gameModeDecks: [],
      gameCode: '',
      faq: [
        ['What games can we play on Bad Cards?', 'We currently have the following awesome games: Bad Cards, Bad Texts, Bad Questions, and Bad Lyrics.'],
        [
          'How do I play?',
          `
            All games are fill-in-the-blank type party games designed to help you and your friends/family let loose. With some slight nuances per game- each mode has a similar flow:
            <ul>
              <li>A question, prompt, or image will be shown to start each round.</li>
              <li>All players will have a hand of random response cards.</li>
              <li>One person per round will be selected as the Judge 👑.</li>
              <li>Each player will choose the funniest response card (or cards) by clicking the "Pick" button.</li>
              <li>After everyone plays their cards, the Judge chooses their favorite response!</li>
              <li>The first player to win seven rounds wins the match (but you can change that in settings!)</li>
            </ul>
          `,
        ],
        ['How many people can play?', 'Each game can have up to 16 players, and an endless number of spectators.'],
        ['How do we get a big group of people in the same game?', 'After going through the game setup flow, each game has a personalized join link that can be shared, Tweeted out, emailed, sent via AIM, or whatever communication platform you use.'],
        ['Is Bad Cards affiliated with Cards Against Humanity? Like Cards Against Humanity online?', 'Hell no. Bad Cards is not affiliated with, associated with, related to, or sponsored by Cards Against Humanity LLC. However, we are closely affiliated with your mom.'],
        ['Is Bad Gifs affiliated with What do you Meme?', 'Hell, hell no. However, see not above about our affiliation with your mom.'],
        ['Will more games be added?', 'Our team is currently working on several other game modes, tournaments, other party games, and other features to add to the site. Our goal is to connect people in fun, interactive ways- one online card game at a time.'],
        ['Do you offer anything besides adult card games?', 'Yes! We have a “Bad Kids” game mode with G- and PG-rated family-friendly content. We’re also close to implementing a rating system that can be applied to any game to filter the content based on your level of appropriateness.'],
        [
          'Why online card games with friends?',
          `
            <p>Sick of carrying a box to your friend’s house? Hate lugging tons of cards around? Ever think about all the waste that goes into printed card games? We want to alleviate those issues and make these games more accessible to all, which is why we’re proud of the millions of players we’ve attracted across the WORLD.</p>
            <p>Our goal is simple: Connect people worldwide - one party game at a time.</p>
          `,
        ],
        ['Is this a free online card game, or do I have to pay?', 'You do not need to pay to play. However, subscribing grants you access to more content, removes ads, and more.'],
        ['I’m an influencer/lead a large online community - is there any way we can collaborate?', 'Hell yeah! We’re building our creator program and would love to connect on our beta. Feel free to send a shout to info@bad.cards.'],
        [
          'What future games do you have planned?',
          `
            We've got a bunch of crazy stuff planned. We can't tip our hat on everything, but here are a few:
            <ul>
              <li><b>InstaBad</b>: The Card Queen plays a photo and you play your best photo caption.</li>
              <li><b>Definitionz [Naming in progress]</b>: A family friendly game. Everyone is given a nonsense word. You provide the best definition from your hand. You can also change settings to allow people to write their own.</li>
            </ul>
          `,
        ],
      ],
      selectedFaq: -1,
      deckListOptions: {
        slidesPerView: 1.5,
        spaceBetween: 12,
        slidesOffsetBefore: 24,
        slidesOffsetAfter: 24,
        breakpoints: {
          1280: {
            slidesPerView: 5,
            slidesOffsetBefore: 0,
            slidesOffsetAfter: 0,
          },
          1170: {
            slidesPerView: 4,
            slidesOffsetBefore: 24,
            slidesOffsetAfter: 24,
          },
          720: {
            slidesPerView: 3,
          },
          480: {
            slidesPerView: 2,
          },
        },
      },
    };
  },
  computed: {
    ...mapState(['user', 'client', 'lang', 'languages', 'gameModes']),
    languageItems() {
      return this.languages.map(({ code: value }) => ({
        label: value,
        value,
      }));
    },
    popularGames() {
      return this.gameModes.filter((mode) => ['standard', 'texts', 'questions', 'memes'].includes(mode.id) && !mode.decks);
    },
    featuredGames() {
      return this.gameModes.filter((mode) => ['gifs', 'free-form', 'lyrics', 'drink'].includes(mode.id));
    },
    newGames() {
      return this.gameModes.filter((mode) => mode.new);
    },
  },
  components: {
    DeckList,
    PageFooter,
  },
  watch: {
    gameCode(value, old) {
      if (value.length > old.length && value.length === 3) {
        this.gameCode += '-';
      }
    },
  },
  methods: {
    ...mapActions(['openAuth', 'openGame']),
    openGameForm() {
      if (this.user.type === 'anonymous') {
        this.openAuth();
        return;
      }
      this.openGame();
    },
    openSubscription() {
      window.dispatchEvent(new CustomEvent('open-subscription'));
    },
    toggleFaq(index) {
      const el = this.$refs.answers[this.selectedFaq];
      if (el) {
        el.style.maxHeight = null;
      }
      this.selectedFaq = index === this.selectedFaq ? -1 : index;
      if (this.selectedFaq > -1) {
        const answer = this.$refs.answers[this.selectedFaq];
        if (answer) {
          answer.style.maxHeight = `${answer.scrollHeight}px`;
        }
      }
    },
    async getPopularDecks(decks) {
      let favorites;
      if (decks.length) {
        const favoritesQuery = query(
          collection(firestore, 'favorites'),
          where('user_id', '==', this.user.id),
          where('deck_id', 'in', decks.map((deck) => deck.id)),
          limit(5),
        );
        favorites = await getDocs(favoritesQuery);
      }
      this.popularDecks = decks.map((deck) => ({
        ...deck,
        favorite: !!favorites?.docs.find((doc) => doc.get('deck_id') === deck.id),
      }));
    },
    async getFreeDecks(decks) {
      let favorites;
      if (decks.length) {
        const favoritesQuery = query(
          collection(firestore, 'favorites'),
          where('user_id', '==', this.user.id),
          where('deck_id', 'in', decks.map((deck) => deck.id)),
          limit(5),
        );
        favorites = await getDocs(favoritesQuery);
      }
      this.freeDecks = decks.map((deck) => ({
        ...deck,
        favorite: !!favorites?.docs.find((doc) => doc.get('deck_id') === deck.id),
      }));
    },
    async getFeaturedDecks(decks) {
      let favorites;
      if (decks.length) {
        const favoritesQuery = query(
          collection(firestore, 'favorites'),
          where('user_id', '==', this.user.id),
          where('deck_id', 'in', decks.map((deck) => deck.id)),
          limit(5),
        );
        favorites = await getDocs(favoritesQuery);
      }
      this.featuredDecks = decks.map((deck) => ({
        ...deck,
        favorite: !!favorites?.docs.find((doc) => doc.get('deck_id') === deck.id),
      }));
    },
    async fetchDecks() {
      this.loadingDecks = true;
      try {
        const decks = await this.client.get('/data/home');
        this.getFeaturedDecks(decks.featured);
        this.getFreeDecks(decks.free);
        this.getPopularDecks(decks.popular);
      } catch (error) {
        showError(error);
      }
      this.loadingDecks = false;
    },
    async getGameModeDecks() {
      this.loadingDecks = true;
      this.gameModeDecks = await Promise.all(this.gameModes.map(async (mode) => {
        const decksQuery = query(
          collection(firestore, 'decks'),
          where('game_modes', 'array-contains', mode.id),
          orderBy('game_count', 'desc'),
          limit(5),
        );
        const result = await getDocs(decksQuery);
        if (result.empty) {
          return [];
        }
        const decks = result.docs.map((doc) => doc.data());
        const favoritesQuery = query(
          collection(firestore, 'favorites'),
          where('user_id', '==', this.user.id),
          where('deck_id', 'in', decks.map((deck) => deck.id)),
          limit(decks.length),
        );
        const favorites = await getDocs(favoritesQuery);
        return decks.map((deck) => ({
          ...deck,
          favorite: !!favorites.docs.find((doc) => doc.get('deck_id') === deck.id),
        }));
      }));
      this.loadingDecks = false;
    },
    async joinGame() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      try {
        const game = await this.client.post('/games/join', {
          code: this.gameCode.toUpperCase(),
        });
        this.$router.push(`/g/${game.id}`);
        sendEvent('join-game', { game_id: game.id });
      } catch (error) {
        console.warn(error);
      }
      this.gameCode = '';
      this.loading = false;
    },
    async createGame(mode, decks, name) {
      if (this.user.type === 'anonymous') {
        this.openAuth();
        return;
      }
      if (this.loading) {
        return;
      }
      this.loading = true;
      try {
        const settings = {
          name: name || `${this.user.name || 'Unknown'}'s game`,
          mode,
          decks: decks || null,
          is_manual: true,
        };
        const game = await this.client.post('/games', settings);
        settings.id = game.id;
        sendEvent('create-game', settings);
        this.$router.push(`/g/${game.id}`);
      } catch (error) {
        console.warn(error);
      }
      this.loading = false;
    },
    async getStreamers() {
      clearTimeout(this.streamersTO);
      try {
        this.streamers = await this.client.get('/streamers');
      } catch (error) {
        console.warn(error);
      }
      this.streamersTO = setTimeout(this.getStreamers, 30000);
    },
    onDeckDeleted() {
      this.fetchDecks();
    },
  },
  mounted() {
    // this.getStreamers();
    this.onDeckDeleted();
    window.addEventListener('deck-deleted', this.onDeckDeleted);
    this.topSwiper = new Swiper(this.$refs.topSwiper, {
      slidesPerView: 1,
      loop: true,
      navigation: {
        nextEl: '.swiper-arrow-next',
        prevEl: '.swiper-arrow-prev',
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
      autoplay: {
        delay: 5000,
      },
      modules: [Autoplay, Pagination, Navigation],
    });
  },
  beforeDestroy() {
    window.removeEventListener('deck-deleted', this.onDeckDeleted);
    clearTimeout(this.streamersTO);
  },
};
</script>

<style scoped>
.page {
  padding: 24px;
}

.container {
  position: relative;
  background: rgba(90,76,212,1);
  background: linear-gradient(142deg, rgba(90,76,212,1) 0%, rgba(60,51,115,1) 50%);
  border-radius: 16px;
  padding: 48px;
  width: 1200px;
  max-width: 100%;
  margin: auto;
}
.container .logo {
  display: block;
  width: 360px;
  max-width: 100%;
  margin: 0 auto;
}
.container h1 {
  margin: 32px 0;
  text-align: center;
}
.container .back-btn {
  position: absolute;
  top: 24px;
  left: 24px;
}

.section {
  margin-top: 32px;
}
.section.mobile {
  display: none;
}
.section .section-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 12px;
}
.section .section-title {
  margin-bottom: 12px;
}
.section .section-header .section-title {
  margin-bottom: 0;
}
.section .section-header .link {
  margin-bottom: 4px;
}

.subscribe-btn {
  font-size: 18px;
  padding: 16px 24px;
}
.subscribe-btn .button-title {
  font-size: 24px;
}

.banner,
.top-slider .banner-slide {
  display: block;
  width: 100%;
  height: 400px;
}
.banner img,
.top-slider .banner-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}
.banner {
  margin: 72px 0 64px;
}
.top-slider .swiper-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
}
.top-slider .swiper-pagination-bullets.swiper-pagination-horizontal {
  position: relative;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
}
.top-slider .swiper-arrow-prev,
.top-slider .swiper-arrow-next {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.top-slider .swiper-controls .material-symbols-outlined {
  font-size: 16px;
}

.tiles {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 24px;
  margin-top: 64px;
}
.tiles .tile {
  display: block;
  width: calc(50% - 12px);
}
.tiles .tile img {
  display: block;
  width: 100%;
  border-radius: 16px;
}

.game-cards {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}
.game-cards .game-card {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: calc(25% - 9px);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgba(90,76,212,1);
  border-radius: 16px;
  min-height: 215px;
}
.game-cards .game-card .game-card-details {
  display: flex;
  flex-direction: column;
  text-align: left;
  flex-grow: 1;
  padding: 16px 24px;
  background-color: rgba(90,76,212,1);
  background: linear-gradient(142deg, rgba(90,76,212,0.85) 0%, rgba(60,51,115,0.85) 50%);
  opacity: 0;
  transition: opacity 0.2s ease-out;
}
.game-cards .game-card:hover .game-card-details {
  opacity: 1;
}
.game-cards .game-card .game-card-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 8px;
}
.game-cards .game-card .game-card-actions {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: auto;
  gap: 8px;
  padding-top: 16px;
}
.game-cards .game-card .game-card-icon {
  position: absolute;
  top: -16px;
  left: -20px;
  width: 48px;
  height: 48px;
  transform: rotateZ(-30deg);
}
.game-cards .game-card .btn.plain {
  background: rgba(0, 0, 0, 0.32);
}

.container p {
  font-size: 20px;
  margin: 64px 0;
  line-height: 1.75;
}

.form .form-step .actions {
  justify-content: center;
}

.game-options {
  margin-top: 48px;
}
.game-options h2 {
  margin-bottom: 24px;
}

.username-form {
  text-align: center;
}
.username-form .label {
  margin-bottom: 16px;
}
.username-form .providers {
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.username-form .form {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.username-form .actions {
  justify-content: center;
}

.game-form {
  text-align: left;
}

.input-field {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  text-align: left;
}
.input-field label {
  display: block;
  width: 100%;
}
.input-field .input {
  margin: 0;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px 24px;
}
.buttons .separator {
  margin-bottom: 12px;
}
.buttons.bottom {
  flex-direction: column;
}
.buttons.bottom .input-field {
  justify-content: center;
  text-align: center;
}
.buttons.bottom .input-field .input {
  flex-grow: 1;
}
.buttons .button-actions {
  display: flex;
  justify-content: center;
  gap: 12px;
  flex-wrap: wrap;
}

.game-menu .buttons {
  gap: 16px 48px;
}
.game-menu .input-field,
.game-menu .buttons > .btn {
  position: relative;
  width: calc(33.3333333% - 32px);
  flex-wrap: nowrap;
}
.game-menu .buttons > .btn,
.game-menu .input-field .input {
  font-size: 24px;
  font-weight: bold;
  height: 64px;
}
.game-menu .input-field .btn {
  position: absolute;
  right: 8px;
}
.game-menu .input-field .input {
  width: 100%;
  padding-right: 64px;
}
.page-container > .container {
  padding: 0;
  background: none;
}

.game-modes {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.game-modes .game-mode {
  width: calc(50% - 8px);
  text-align: left;
  background: #5f51d7;
  padding: 12px 16px;
  border-radius: 8px;
  opacity: 0.5;
}

.game-modes .game-mode[selected] {
  opacity: 1;
}

.game-modes .game-mode .game-mode-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 8px;
}

.game-browser {
  width: 100%;
  margin-top: 48px;
}

.faq {
  margin-top: 96px;
}
.faq h2 {
  font-size: 48px;
  text-align: center;
}
.faq .item {
  position: relative;
  display: block;
  width: 100%;
  padding: 24px 48px 12px 24px;
  font-size: 18px;
  background: #19191a;
  border-radius: 16px;
  margin-top: 24px;
  text-align: left;
}
.faq .item .question {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 12px;
}
.faq .item .answer {
  position: relative;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}
.faq .item .material-symbols-outlined {
  position: absolute;
  top: 24px;
  right: 12px;
  transition: transform 0.2s ease-out;
}
.faq .item.active .material-symbols-outlined {
  transform: rotateZ(90deg);
}

.games-list {
  position: relative;
  width: 100%;
  height: 400px;
  overflow: auto;
  margin-top: 24px;
}

.games-list .game {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  gap: 24px;
  background: rgba(0, 0, 0, 0.48);
  border-radius: 8px;
  margin-bottom: 8px;
}

.games-list .game .game-name {
  font-size: 16px;
  margin-bottom: 4px;
}

.games-list .game .game-settings {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
}

.games-list .game .game-stats {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 6px;
}
.games-list .game .game-stat {
  display: flex;
  align-items: center;
  gap: 4px;
  background: rgba(0, 0, 0, 0.48);
  border-radius: 16px;
  font-size: 14px;
  padding: 4px 8px;
}
.games-list .game .game-stat .material-symbols-outlined {
  font-size: 16px;
}

.empty-state {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: italic;
  text-align: center;
}

.streams {
  margin-top: 40px;
}
.streams .streams-title {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 12px;
}
.streams .streams-title .icon {
  width: 18px;
  margin-right: 8px;
}
.streams .streams-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
}
.streams .stream {
  position: relative;
  display: block;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 3px solid #9146FF;
}
.streams .stream img {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.streams .stream .stream-name {
  position: absolute;
  display: none;
  padding: 4px 8px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.48);
  top: 100%;
  margin-top: 8px;
  left: 50%;
  transform: translateX(-50%);
}
.streams .stream:hover .stream-name {
  display: block;
}

@media screen and (max-width: 1280px) {
  .game-cards .game-card {
    width: calc(33.333333% - 8px);
  }
  .deck-list-container {
    margin: 0 -24px;
  }
  .game-menu .buttons {
    gap: 24px;
    flex-wrap: wrap;
  }
  .game-menu .buttons > .btn,
  .game-menu .input-field {
    width: calc(33.333333% - 16px);
  }
  .game-menu .buttons > .btn,
  .game-menu .input-field .input {
    font-size: 18px;
  }
}

@media screen and (max-width: 1024px) {
  .game-cards .game-card .game-card-details {
    opacity: 1;
  }
  .game-menu .buttons {
    margin-top: 32px;
  }
}

@media screen and (max-width: 800px) {
  .game-cards .game-card {
    width: calc(50% - 6px);
  }
  .game-menu .input-field {
    order: 3;
    width: 100%;
  }
  .game-menu .buttons > .btn {
    width: calc(50% - 12px);
  }
  .banner,
  .top-slider .banner-slide {
    height: 280px;
  }
}

@media screen and (max-width: 640px) {
  .input-field {
    justify-content: center;
    text-align: center;
  }
  .tiles .tile {
    width: 100%;
  }
}

@media screen and (max-width: 580px) {
  .game-cards .game-card {
    width: 100%;
    min-height: 154px;
  }
  .container {
    padding: 48px 24px;
  }
  .container h1 {
    font-size: 20px;
    margin-top: 0;
  }
  .container .logo {
    display: none;
  }
  .game-modes .game-mode {
    width: 100%;
  }
  .section.mobile {
    display: block;
  }
  .buttons,
  .buttons.bottom {
    flex-direction: column;
    align-items: center;
  }
  .buttons .separator {
    margin-bottom: 0;
  }
  .game-menu .buttons > .btn {
    width: 100%;
  }
  .banner,
  .top-slider .banner-slide {
    height: 200px;
  }
}
</style>
